:root {
    --background-000: #000000;
    --background-005: #090a0d;
    --background-010: #121319;
    --background-015: #1d1e22;
    --background-020: #26282d;
    --background-025: #31333a;
    --background-030: #3c3e45;
    --background-035: #47494f;
    --background-040: #52545b;
    --background-045: #5d5f65;
    --background-050: #686a70;
    --background-055: #73757b;
    --background-060: #7e8086;
    --background-065: #898b91;
    --background-070: #94969c;
    --background-075: #9fa1a7;
    --background-080: #aaacaf;
    --background-085: #b5b7ba;
    --background-090: #c0c2c5;
    --background-095: #cbcdcf;
    --background-100: #d6d8da;
    --background-105: #e1e3e4;
    --background-110: #ecedee;
    --background-115: #f7f8f8;
    --background-120: #ffffff;

    --foreground-000: #ffffff;
    --foreground-005: #f7f8f8;
    --foreground-010: #ecedee;
    --foreground-015: #e1e3e4;
    --foreground-020: #d6d8da;
    --foreground-025: #cbcdcf;
    --foreground-030: #c0c2c5;
    --foreground-035: #b5b7ba;
    --foreground-040: #aaacaf;
    --foreground-045: #9fa1a7;
    --foreground-050: #94969c;
    --foreground-055: #898b91;
    --foreground-060: #7e8086;
    --foreground-065: #73757b;
    --foreground-070: #686a70;
    --foreground-075: #5d5f65;
    --foreground-080: #52545b;
    --foreground-085: #47494f;
    --foreground-090: #3c3e45;
    --foreground-095: #31333a;
    --foreground-100: #26282d;
    --foreground-105: #1d1e22;
    --foreground-110: #121319;
    --foreground-115: #090a0d;
    --foreground-120: #000000;
}
