:root {
  --header-height: 40px;
  --drawer-width: 250px;
}

body {
  margin: 0;
  color: var(--foreground-015);
  background-color: var(--background-015);
}
